import { useTranslation } from 'react-i18next'

const HeaderItems = () => {
  const { t } = useTranslation()

  return {
    settingsMenuItems: [
      { name: `language`, label: `${t('language')}` },
      { name: `currency`, label: `${t('currency')}` },
      { name: `darkMode`, label: `${t('darkMode')}` },
    ],
    comingSoonMenuItems: [
      { name: `darkModeComingSoon`, label: '' },
    ],

    loginMenuItems: userSessionExpired => {
      return [
        {
          name: process.env.LS_LOGIN,
          label: userSessionExpired ? `${t('login')}` : `${t('logout')}`,
          isExternal: true,
        },
        {
          name: process.env.LS_SIGNUP,
          label: `${t('signup')}`,
          isExternal: true,
        },
      ]
    },

    comingSoonLoginMenuItems: lang => {
      return [
        {
          name: `${lang}/coming-soon`,
          label: `${t('login')}`,
          isExternal: false,
        },
        {
          name: `${lang}/coming-soon`,
          label: `${t('signup')}`,
          isExternal: false,
        },
      ]
    },

    menuItems: lang => [
      {
        name: 'whyUs',
        label: `${t('whyUs')}`,
        items: [
          { name: `${lang}/why-us`, label: `${t('overview')}` },
          { name: `${lang}/platform`, label: `${t('tradingPlatform')}` },
          { name: `${lang}/portfolio-management`, label: `${t('portfolio')}` },
        ],
      },
      {
        name: `${lang}/pricing`,
        label: `${t('pricing')}`,
      },
      {
        name: 'resources',
        label: `${t('resources')}`,
        items: [
          { name: `${lang}/exchanges`, label: `${t('exchanges')}` },
          {
            name: `${lang}/cryptocurrencies`,
            label: `${t('cryptocurrencies')}`,
          },
          { name: `${lang}/markets`, label: `${t('markets')}` },
        ],
      },
      // {
      //   name: 'resources',
      //   label: `${t('resources')}`,
      //   items: [
      //     {
      //       name: 'https://insights.coinigy.com',
      //       label: `${t('insights')}`,
      //       isExternal: true,
      //     },
      //     {
      //       name: 'https://www.coinigy.com/bitcoin-data/',
      //       label: `${t('historicalData')}`,
      //       isExternal: true,
      //     },
      //     {
      //       name: 'https://community.coinigy.com',
      //       label: `${t('community')}`,
      //       isExternal: true,
      //     },
      //   ],
      // },
      {
        name: `en/learning`,
        label: `${t('learning')}`,
      },
      {
        name: `${lang}/security`,
        label: `${t('security')}`,
      },
      {
        name: `${lang}/support`,
        label: `${t('support')}`,
      },
      // {
      //   name: 'the-platform',
      //   label: `${t('platform')}`,
      //   items: [
      //     { name: `${lang}/platform/overview`, label: `${t('overview')}` },
      //     { name: `${lang}/platform/apps`, label: `${t('apps')}` },
      //     { name: `${lang}/platform/api`, label: `${t('api')}` },
      //     { name: `${lang}/platform/supported-exchanges`, label: `${t('tables.supportedExchs')}` },
      //     { name: `${lang}/platform/pricing`, label: `${t('pricing')}` },
      //   ],
      // },
      // {
      //   name: 'company',
      //   label: `${t('company')}`,
      //   items: [
      //     { name: `${lang}/company/about`, label: `${t('ourStory')}` },
      //     { name: `${lang}/company/testimonials`, label: `${t('testimonials')}` },
      //     { name: `${lang}/company/partnerships`, label: `${t('partnerships')}` },
      //     { name: `${lang}/company/referrals`, label: `${t('referrals')}` },
      //     { name: `${lang}/company/contact`, label: `${t('contact')}` },
      //     {
      //       name: 'https://news.coinigy.com',
      //       label: `${t('news')}`,
      //       isExternal: true,
      //     },
      //     {
      //       name: 'https://careers.coinigy.com',
      //       label: `${t('careers')}`,
      //       isExternal: true,
      //     },
      //   ],
      // },
    ],

    topMenuItems: lang => [
      { name: `${lang}/exchanges`, label: `${t('exchanges')}` },
      { name: `${lang}/currencies`, label: `${t('currencies')}` },
      { name: `${lang}/markets`, label: `${t('markets')}` },
    ],
  }
}

export default HeaderItems
