import React, { useState, useContext, useEffect, useRef } from 'react'
import loadable from '@loadable/component'
import { Container, Row } from 'react-bootstrap'
// import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import Link from '../Link'
import theme from '../../utils/theme'
import GlobalContext from '../../context/GlobalContext'
import Offcanvas from '../Offcanvas'
import { breakpoints } from '../../utils'
import * as styles from '../../sections/landing1/Home.module.css'
import { Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import * as userActions from '../../store/actions/userActions'
import { useDispatch } from 'react-redux'
import DarkModeIcon from '../../assets/image/svg/ic_moon_solid.inline.svg'
import LightModeIcon from '../../assets/image/svg/ic_sun.inline.svg'
import ProfileIcon from '../../assets/image/svg/ProfileIcon.inline.svg'
import NewTabIcon from '../../assets/image/svg/ic_newtab16.inline.svg'
import LsHeaderIcon from '../../assets/image/svg/LsHeaderIcon.inline.svg'

import MenuIcon from '../../assets/image/svg/ic_menu.inline.svg'
import SearchIcon from '../../assets/image/svg/search-icon.inline.svg'
import { Button } from 'react-bootstrap'
import { Text, Box } from '../../components/Core'
import { Menu, MenuDropdown, SiteHeader, ToggleButton } from './HeaderStyle'

import HeaderItems from './HeaderItems'
import HeaderConfig from './HeaderConfig'
import {
  useWindowResize,
  useScrollOffset,
  useHandleDarkModeSwitch,
  useWindowScroll,
} from './HeaderHooks'

const Logo = loadable(() => import('../Logo'))
// const LangSelector = loadable(() => import('./LangSelector'))
const CurrSelector = loadable(() => import('./CurrSelector'))
// const DarkModeSwitch = loadable(() => import('../Core/DarkModeSwitch'))
const NestedMenu = loadable(() => import('../NestedMenu'))
const InputSearch = loadable(() => import('../InputSearch'))

const Header = props => {
  // Base Content
  const { location } = props
  const { t } = useTranslation('landing', { useSuspense: false });

  const i18nLang = HeaderConfig().i18nLang()
  const metaData = HeaderConfig().metaData
  const csrfCoinigy = HeaderConfig().csrfCoinigy
  const userSessionExpired = HeaderConfig().userSessionExpired
  const langs = metaData.site.siteMetadata.languages

  let newToken = HeaderConfig().newToken
  let lang = HeaderConfig().setLang(langs, i18nLang)

  const userSettings = HeaderConfig().userSettings
  const userDarkModeSelector = HeaderConfig().userDarkModeSelector
  const userDarkMode = HeaderConfig().userDarkMode(
    userSessionExpired,
    userDarkModeSelector,
    userSettings
  )
  const userDarkModeSettings = HeaderConfig().userDarkModeSettings(userSettings)
  const headerDark = HeaderConfig().headerDark
  const settingsCurr = HeaderConfig().settingsCurr(userSettings)
  const settingsMenuItems = HeaderItems().settingsMenuItems
  const comingSoonMenuItems = HeaderItems().comingSoonMenuItems
  const comingSoonLoginMenuItems = HeaderItems().comingSoonLoginMenuItems(lang)
  const menuItems = HeaderItems().menuItems(lang)

  // Default Header Config.
  HeaderConfig().defaultConfig(location, lang)

  // Hooks
  const dispatch = useDispatch()
  const csrfCookie = useRef(null)
  const gContext = useContext(GlobalContext)

  const [openSearch, setOpenSearch] = useState(false)
  const [acceptedCookiesValue, setAcceptedCookiesValue] = useState(null)

  // Custom Hooks
  const [windowWidth, resizeWidth] = useWindowResize()
  const [offset, setOffset] = useWindowScroll()

  useEffect(() => {
    resizeWidth()
    setOffset()
  }, [])

  // Disabling checkPHPConnectionStatus temporarily

  // if (props.headerType !== 'comingSoon') {
  //   useEffect(() => useUserActions(dispatch, userActions), [dispatch])
  // }

  useEffect(() => {
    document.cookie = "darkModeCookie" + "=" + (userDarkMode === true ?  "Dark" : "Light") + "; path=/";
  }, [])
  
  useEffect(
    () => useScrollOffset(userActions, dispatch, offset),
    [offset, dispatch]
  )

  useEffect(
    () => typeof document !== `undefined` && getCookie(),
    [acceptedCookiesValue]
  )

  useEffect(() => {
    userSessionExpired === false &&
      csrfCookie !== csrfCoinigy &&
      dispatch(userActions.fetchCsrf())
  }, [csrfCoinigy, userSessionExpired, csrfCookie, dispatch])

  // Disabling fetchUser temporarily

  // useEffect(() => {
  //   useFetchUser(
  //     userSessionExpired,
  //     dispatch,
  //     userActions,
  //     csrfCookie,
  //     csrfCoinigy,
  //     settingsCurr
  //   )
  // }, [dispatch, userSessionExpired, csrfCoinigy, settingsCurr, csrfCookie])

  const handleDarkModeSwitch = () =>
    useHandleDarkModeSwitch(
      dispatch,
      userActions,
      userDarkModeSelector,
      userSessionExpired,
      newToken,
      userDarkModeSettings
    )

  const getCookie = () => {
    if (
      typeof document !== `undefined` &&
      document.cookie.indexOf('accepted_cookies') > -1
    ) {
      setAcceptedCookiesValue(
        document.cookie
          .split('; ')
          .find(row => row.startsWith('accepted_cookies='))
          .split('=')[1]
      )
    }
  }

  const setCookie = value => {
    document.cookie = 'accepted_cookies=' + (value || '') + ';'
    getCookie()
  }

  if (props.headerType === 'comingSoon') {
    return (
      
      <SiteHeader
        dark={userDarkModeSelector}
        userDarkMode={userDarkModeSelector}
      >
        {windowWidth > breakpoints.xxl && (
          <Container fluid>
            <nav
              className="navbar site-navbar offcanvas-active navbar-expand-lg navbar-light p-0"
              aria-label="Secondary Menu"
            >
              <Menu
                userDarkMode={userDarkModeSelector}
                headerDark={headerDark}
                className="navbar-nav d-flex d-none align-items-center"
                style={{justifyContent: 'space-between', width: '100%'}}
              >
                <li>
                  <Row className="mr-3 ml-3" >
                    <a
                      aria-label="Lightspeed Financial"
                      className="ml-2"
                      href="https://www.lightspeed.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Text
                        variant="small"
                        color={userDarkModeSelector ? 'rgba(255, 255, 255, 0.56)' : 'rgba(0, 0, 0, 0.56)'}
                      >
                        Lightspeed Financial
                        <LsHeaderIcon
                          style={{
                            marginLeft: '10px',
                            marginBottom: '4px',
                            width: '12px',
                            height: '12px',
                          }}
                          aria-label={t('newTab')}
                          role="img"
                        />
                      </Text>
                    </a>
                  </Row>
                </li>
                <li className="nav-item">
                  <div
                    style={{ cursor: 'pointer', padding: 12 }}
                    className="d-flex align-items-center"
                  >
                    {userDarkModeSelector ? (
                      <Row
                        onClick={() => handleDarkModeSwitch()}
                        className="mr-3 ml-3"
                        style={{ alignItems: 'center' }}
                      >
                        <LightModeIcon
                          width="16"
                          height="16"
                          className="mr-2"
                          aria-hidden="true"
                          data-testid="light-mode-icon"
                          // onClick={() => handleDarkModeSwitch()}
                          style={{
                            filter: 'invert(80%)',
                          }}
                        />
                        <Text
                          variant="small"
                          style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                        >
                          {t('lightTheme')}
                        </Text>
                      </Row>
                    ) : (
                      <Row
                        onClick={() => handleDarkModeSwitch()}
                        className="mr-3 ml-3"
                        style={{ alignItems: 'center' }}
                      >
                        <DarkModeIcon
                          width="16"
                          height="16"
                          className="mr-2"
                          aria-hidden="true"
                          data-testid="dark-mode-icon"
                          // onClick={() => handleDarkModeSwitch()}
                          style={{
                            filter: 'invert(20%)',
                          }}
                        />
                        <Text
                          variant="small"
                          style={{ color: 'rgba(0, 0, 0, 0.56)' }}
                        >
                          {t('darkTheme')}
                        </Text>
                      </Row>
                    )}
                  </div>
                </li>
              </Menu>
            </nav>

            <Divider
              style={{
                backgroundColor: userDarkModeSelector
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.1)',
              }}
            />
          </Container>
          )}
          {/* Mobile Menu Container */}
          {windowWidth < breakpoints.xxl && (
            <Row style={{justifyContent: 'right'}}>
              <div className="mr-3">
              <ToggleButton
                className={`navbar-toggler btn-close-off-canvas ml-3 ${
                  gContext.visibleOffCanvas && 'collapsed'
                }`}
                type="button"
                data-toggle="collapse"
                data-target="#mobile-menu"
                aria-controls="mobile-menu"
                aria-expanded="false"
                aria-label="Menu"
                onClick={gContext.toggleOffCanvas}
                dark={userDarkModeSelector}
                userDarkMode={userDarkModeSelector}
                headerDark={headerDark}
              >
              <MenuIcon
                width="20"
                height="20"
                style={{ filter: userDarkModeSelector ? `invert(90%)` : `invert(10%)` }}
                aria-label="Menu"
              />
            </ToggleButton>
              <Offcanvas
                show={gContext.visibleOffCanvas}
                onHideOffcanvas={gContext.toggleOffCanvas}
                comingSoon={true}
              >
                <NestedMenu comingSoon={true} menuItems={comingSoonMenuItems} className="mt-3" />
              </Offcanvas>
            </div>
            </Row>
          )}
      </SiteHeader>
    

      
    )
  }

  return (
    <>
      <SiteHeader
        className={`sticky-header ${!headerDark ? 'scrolling' : ''} ${
          !headerDark ? 'reveal-header' : ''
        }`}
        dark={userDarkMode}
        userDarkMode={userDarkMode}
      >
        {windowWidth > breakpoints.lg && (
          <Container fluid>
            <nav
              className="navbar site-navbar offcanvas-active navbar-expand-lg navbar-light p-0"
              aria-label="Secondary Menu"
            >
              <Menu
                userDarkMode={userDarkMode}
                headerDark={headerDark}
                className="navbar-nav d-flex d-none align-items-center"
              >
                <li>
                  <Row className="mr-3 ml-3" style={{ alignItems: 'center' }}>
                    <a
                      className="ml-2"
                      href="https://www.lightspeed.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Text
                        variant="small"
                        style={{
                          color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
                          fontWeight: 'bold',
                        }}
                      >
                        Lightspeed Financial
                        <LsHeaderIcon
                          style={{
                            marginLeft: '10px',
                            marginBottom: '4px',
                            width: '12px',
                            height: '12px',
                          }}
                          aria-label={t('newTab')}
                          role="img"
                        >
                        </LsHeaderIcon>
                      </Text>
                    </a>
                  </Row>
                </li>
              </Menu>
              <Menu
                userDarkMode={userDarkMode}
                headerDark={headerDark}
                className="navbar-nav d-flex d-none align-items-center ml-auto"
              >
                <li className="nav-item">
                  <CurrSelector white={userDarkMode} />
                </li>
                <li className="nav-item">
                  <div
                    style={{ cursor: 'pointer', padding: 12 }}
                    className="d-flex align-items-center"
                  >
                    {userDarkMode ? (
                      <Row
                        onClick={() => handleDarkModeSwitch()}
                        className="mr-3 ml-3"
                        style={{ alignItems: 'center' }}
                      >
                        <LightModeIcon
                          width="16"
                          height="16"
                          className="mr-2"
                          aria-hidden="true"
                          data-testid="light-mode-icon"
                          // onClick={() => handleDarkModeSwitch()}
                          style={{
                            filter: 'invert(80%)',
                          }}
                        />
                        <Text
                          variant="small"
                          style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                        >
                          {t('lightTheme')}
                        </Text>
                      </Row>
                    ) : (
                      <Row
                        onClick={() => handleDarkModeSwitch()}
                        className="mr-3 ml-3"
                        style={{ alignItems: 'center' }}
                      >
                        <DarkModeIcon
                          width="16"
                          height="16"
                          className="mr-2"
                          aria-hidden="true"
                          data-testid="dark-mode-icon"
                          // onClick={() => handleDarkModeSwitch()}
                          style={{
                            filter: 'invert(20%)',
                          }}
                        />
                        <Text
                          variant="small"
                          style={{ color: 'rgba(0, 0, 0, 0.56)' }}
                        >
                          {t('darkTheme')}
                        </Text>
                      </Row>
                    )}
                  </div>
                </li>
                {userSessionExpired === false ? (
                  <ProfileIcon className="ml-3" width="16" />
                ) : null}
                {userSessionExpired === false ? (
                  <li className="nav-item dropdown">
                    <a
                      aria-label="profile"
                      className="nav-link"
                      role="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                      href="/#"
                      onClick={e => e.preventDefault()}
                    >
                      {t('profile')}
                    </a>
                    <MenuDropdown
                      className="menu-dropdown"
                      dark={userDarkMode}
                      userDarkMode={userDarkMode}
                    >
                      <li className="drop-menu-item">
                        <Link aria-label="balances" to={`/${lang}/balances`}>
                          {t('balances')}
                        </Link>
                      </li>
                      <li className="drop-menu-item">
                        <Link aria-label="orders" to={`/${lang}/orders`}>
                          {t('orders')}
                        </Link>
                      </li>
                      <li className="drop-menu-item">
                        <Link aria-label="Account" to={`/${lang}/account`}>
                          {t('myAccount')}
                        </Link>
                      </li>
                      <li className="drop-menu-item">
                          <Link
                            to={`/${lang}/coming-soon`}
                            aria-label={
                              userSessionExpired
                                ? `${t('login')}`
                                : `${t('logout')}`
                            }
                            // className="nav-link nav-link-small nav-link-blue"
                            // href={
                            //   userSessionExpired 
                            //   ? process.env.LS_LOGIN
                            //   : process.env.LS_LOGOUT
                            // }
                            // rel="noopener noreferrer"
                            style={{color: 'lightGray'}}
                          >
                            {userSessionExpired
                              ? `${t('login')}`
                              : `${t('logout')}`}
                          </Link>
                      </li>
                    </MenuDropdown>
                  </li>
                ) : null}

                
              </Menu>
            </nav>

            <Divider
              style={{
                backgroundColor: userDarkMode
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.1)',
              }}
            />
          </Container>
        )}

        <Container fluid>
          <nav
            className="navbar site-navbar offcanvas-active navbar-expand-lg navbar-light"
            aria-label="Main Menu"
          >
            {/* <!-- Brand Logo--> */}
            <div className="brand-logo">
              <Logo className="logo-small" white={userDarkMode} />
            </div>
            <div className="collapse navbar-collapse">
              <div className="navbar-nav ml-3 mr-3">
                <Menu
                  className="navbar-nav d-none d-lg-flex"
                  dark={userDarkMode}
                  userDarkMode={userDarkMode}
                  headerDark={headerDark}
                >
                  {menuItems.map(
                    (
                      { label, isExternal = false, lang, name, items, ...rest },
                      index
                    ) => {
                      const hasSubItems = Array.isArray(items)
                      return (
                        <React.Fragment key={name + index}>
                          {hasSubItems ? (
                            <li className="nav-item dropdown" {...rest}>
                              <a
                                aria-label={label}
                                className="nav-link"
                                role="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                href="/#"
                                onClick={e => e.preventDefault()}
                              >
                                {label}
                              </a>
                              <MenuDropdown
                                className="menu-dropdown"
                                dark={userDarkMode}
                                userDarkMode={userDarkMode}
                              >
                                {items.map((subItem, indexSub) => {
                                  const hasInnerSubItems = Array.isArray(
                                    subItem.items
                                  )
                                  return (
                                    <React.Fragment
                                      key={subItem.name + indexSub}
                                    >
                                      {hasInnerSubItems ? (
                                        <li className="drop-menu-item dropdown">
                                          <a
                                            aria-label={subItem.label}
                                            className="dropdown-toggle"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-expanded="false"
                                            href="/#"
                                            onClick={e => e.preventDefault()}
                                          >
                                            {subItem.label}
                                          </a>
                                          <MenuDropdown
                                            className="menu-dropdown dropdown-right"
                                            dark={userDarkMode}
                                            userDarkMode={userDarkMode}
                                          >
                                            {subItem.items.map(
                                              (itemInner, indexInnerMost) => (
                                                <li
                                                  className="drop-menu-item"
                                                  key={
                                                    itemInner.name +
                                                    indexInnerMost
                                                  }
                                                >
                                                  {itemInner.isExternal ? (
                                                    <a
                                                      href={`${itemInner.name}`}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      aria-label={
                                                        itemInner.label
                                                      }
                                                    >
                                                      {itemInner.label}
                                                    </a>
                                                  ) : (
                                                    <Link
                                                      aria-label={
                                                        itemInner.label
                                                      }
                                                      to={`/${itemInner.name}/`}
                                                    >
                                                      {itemInner.label}
                                                    </Link>
                                                  )}
                                                </li>
                                              )
                                            )}
                                          </MenuDropdown>
                                        </li>
                                      ) : (
                                        <li className="drop-menu-item">
                                          {subItem.isExternal ? (
                                            <a
                                              href={`${subItem.name}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              aria-label={subItem.label}
                                            >
                                              {subItem.label}
                                              <NewTabIcon
                                                style={{
                                                  filter: userDarkMode
                                                    ? 'invert(40%)'
                                                    : 'invert(50%)',
                                                  marginBottom: '2px',
                                                  marginLeft: '4px',
                                                  width: '16px',
                                                  height: '16px',
                                                }}
                                                aria-label={t('newTab')}
                                                role="img"
                                              />
                                            </a>
                                          ) : (
                                            <Link
                                              aria-label={subItem.label}
                                              to={`/${subItem.name}/`}
                                            >
                                              {subItem.label}
                                            </Link>
                                          )}
                                        </li>
                                      )}
                                    </React.Fragment>
                                  )
                                })}
                              </MenuDropdown>
                            </li>
                          ) : (
                            <li className="nav-item" {...rest}>
                              {isExternal ? (
                                <a
                                  className="nav-link"
                                  href={`${name}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  aria-label={label}
                                >
                                  {label}
                                  <NewTabIcon
                                    style={{
                                      filter: userDarkMode
                                        ? 'invert(40%)'
                                        : 'invert(50%)',
                                      marginBottom: '2px',
                                      marginLeft: '4px',
                                      width: '16px',
                                      height: '16px',
                                    }}
                                    aria-label={t('newTab')}
                                    role="img"
                                  />
                                </a>
                              ) : (
                                <Link
                                  className="nav-link"
                                  aria-label={label}
                                  to={`/${name}/`}
                                >
                                  {label}
                                </Link>
                              )}
                            </li>
                          )}
                        </React.Fragment>
                      )
                    }
                  )}
                </Menu>
              </div>
            </div>
            <ToggleButton
              className={`navbar-toggler btn-close-off-canvas ml-3 ${
                gContext.visibleOffCanvas && 'collapsed'
              }`}
              type="button"
              data-toggle="collapse"
              data-target="#mobile-menu"
              aria-controls="mobile-menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={gContext.toggleOffCanvas}
              dark={userDarkMode}
              userDarkMode={userDarkMode}
              headerDark={headerDark}
            >
              {/* <i className="icon icon-simple-remove icon-close"></i>
                <i className="icon icon-menu-34 icon-burger d-block"></i> */}

              <MenuIcon
                width="20"
                height="20"
                style={{ filter: userDarkMode ? `invert(90%)` : `invert(10%)` }}
                aria-label="Menu"
              />
            </ToggleButton>
            {windowWidth > breakpoints.lg && (
              <Menu
                userDarkMode={userDarkMode}
                headerDark={headerDark}
                className="navbar-nav d-flex d-none align-items-center ml-lg-auto"
              >
                {/* <li className="nav-item nav-item-border pl-3 pr-1">
                  <LangSelector
                    location={location}
                    white={userDarkMode}
                  />
                </li> */}

                <li className="nav-item mx-4">
                  <Row style={{ alignItems: 'center' }}>
                    <SearchIcon
                      style={{ cursor: 'pointer' }}
                      width="16"
                      height="16"
                      data-testid="search-icon"
                      onClick={() => setOpenSearch(!openSearch)}
                    />
                    {openSearch === true && <InputSearch />}
                  </Row>
                </li>
                <li className="mr-3 nav-item">
                    <Link
                      to={`/${lang}/coming-soon`}
                      aria-label={
                        userSessionExpired ? `${t('login')}` : `${t('logout')}`
                      }
                      className="nav-link nav-link-small nav-link-gray"
                      style={{borderColor: 'lightgray', border: 1}}
                      // href={
                      //   userSessionExpired 
                      //   ? process.env.LS_LOGIN
                      //   : process.env.LS_LOGOUT
                      // }
                      // rel="noopener noreferrer"
                    >
                      {userSessionExpired ? `${t('login')}` : `${t('logout')}`}
                    </Link>
                </li>
                {userSessionExpired && (
                  <li className="mr-3 nav-item">
                      <Link
                        to={`/${lang}/coming-soon`}
                        aria-label={t('signup')}
                        className="nav-link nav-link-small nav-link-blue"
                        // href={process.env.LS_SIGNUP}
                        // rel="noopener noreferrer"
                      >
                        {t('signup')}
                      </Link>
                  </li>
                )}
              </Menu>
            )}
          </nav>
        </Container>
      </SiteHeader>

      {/* Mobile Menu Container */}
      {windowWidth < breakpoints.xxl && (
        <Offcanvas
          show={gContext.visibleOffCanvas}
          onHideOffcanvas={gContext.toggleOffCanvas}
        >
          <NestedMenu menuItems={menuItems} />
          <NestedMenu menuItems={comingSoonLoginMenuItems} />
          <NestedMenu menuItems={settingsMenuItems} className="mt-3" />
          <InputSearch />
        </Offcanvas>
      )}

      {acceptedCookiesValue === 'understood' && (
        <Box
          className="d-flex justify-content-center"
          style={{
            zIndex: 99999,
            position: 'fixed',
            bottom: 0,
            padding: 20,
            backgroundColor: userDarkMode
              ? theme.colors.darkGray
              : theme.colors.light,
            width: '100%',
            boxShadow: '0 0 20px rgba(0, 0, 0, 0.20)',
          }}
        >
          <Text
            style={{
              color: userDarkMode ? theme.colors.lightGray : theme.colors.black,
            }}
            className="mr-3 align-self-center"
            variant="small"
          >
            {t('cookiesPopup')}
            <Link to={`/${lang}/privacy`}>
              <span
                className="mt-1 mb-1 ml-1"
                style={{
                  color: userDarkMode
                    ? theme.colors.lightGray
                    : theme.colors.black,
                  textDecoration: 'underline',
                }}
              >
                {t('privacy')}
              </span>
            </Link>
            .
          </Text>
          <Button
            onClick={() => setCookie('understood')}
            className={`${styles.aTagButtonSmall} align-self-center`}
            style={{
              backgroundColor: theme.colors.primary,
              borderColor: theme.colors.primary,
              marginTop: 1,
              marginBottom: 1,
            }}
          >
            {t('understood')}
          </Button>
        </Box>
      )}
    </>
  )
}
export default Header
