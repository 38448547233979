import styled from 'styled-components'
import theme from '../../utils/theme'
import { device } from '../../utils'

const {
  primary,
  primaryLight,
  lightShade,
  darkShade,
  dark,
  light,
  black,
  lightGray,
} = theme.colors

const setColor = (props, light, dark) => {
  const itemColor = props.userDarkMode ? light : dark
  return `${itemColor} !important`
}

export const Menu = styled.ul`
  @media ${device.lg} {
    display: flex;
    justify-content: flex-end;
  }

  .dropdown-toggle {
    cursor: pointer;
  }

  > li {
    > .nav-link {
      @media ${device.lg} {
        color: ${props => setColor(props, lightShade, darkShade)};
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        padding-left: 18px !important;
        padding-right: 18px !important;
      }

      &.nav-link-small {
        color: ${props => setColor(props, lightShade, darkShade)};
        font-size: 14px;
        text-align: left;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
      }

      &.nav-link-blue {
        border: 1px solid;
        border-color: ${props => setColor(props, primaryLight, primary)};
        border-radius: 5px;
        color: ${props => setColor(props, primaryLight, primary)};
      }

      &:hover {
        color: ${props => setColor(props, primaryLight, primary)};
      }

      &.nav-link-blue:hover {
        border-color: ${props => setColor(props, light, primaryLight)};
        color: ${props => setColor(props, light, primaryLight)};
      }
    }
  }

  .nav-item {
    color: ${props => setColor(props, lightShade, darkShade)};
  }

  .nav-item-border {
    border-right: 1px solid
      ${props =>
        setColor(props, 'rgba(255, 255, 255, 0.1)', 'rgba(0, 0, 0, 0.2)')};
  }

  .nav-item.dropdown {
    @media ${device.lg} {
      position: relative;
      z-index: 99999;
    }

    &:hover {
      > .menu-dropdown {
        @media ${device.lg} {
          top: 100%;
          opacity: 1;
          pointer-events: visible;
        }
      }
    }
  }
`

export const MenuDropdown = styled.ul`
  list-style: none;

  @media ${device.lg} {
    top: 110%;
    position: absolute;
    min-width: 227px;
    max-width: 227px;
    box-shadow: 0 30px 40px rgba(0, 0, 0, 0.2);
    border: 1px solid ${props => setColor(props, darkShade, lightShade)};
    background-color: ${props => setColor(props, dark, light)};
    padding: 15px 0px;
    z-index: 99999;
    opacity: 0;
    transition: opacity 0.4s, top 0.4s;
    pointer-events: none;
    left: 0;
    border-radius: 0 0 10px 10px;
    display: block;
    border-top: ${props => `3px solid ${primary}`};
  }

  > .drop-menu-item {
    color: ${props => setColor(props, lightShade, darkShade)};
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.2px;

    a {
      color: ${props => setColor(props, lightShade, darkShade)};
      transition: all 0.3s ease-out;
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 10px;
      padding-bottom: 10px;
      &.dropdown-toggle::after {
        display: inline-block;
        vertical-align: 0.255em;
        content: '';
        border-top: 0.325em solid;
        border-right: 0.325em solid transparent;
        border-bottom: 0;
        border-left: 0.325em solid transparent;
        position: relative;
        top: 1px;
        margin-left: auto;
        transform: rotate(-90deg);
        transition: 0.4s;
      }
    }

    &:hover {
      > a {
        color: ${props => setColor(props, primaryLight, primary)};
        background-color: ${props => setColor(props, black, light)};
        text-decoration: none;
        &::after {
          transform: rotate(0deg);
        }
      }
    }

    &.dropdown {
      position: relative;

      &:hover {
        > .menu-dropdown {
          @media ${device.lg} {
            top: 10px;
            opacity: 1;
            pointer-events: visible;
            transform: translateX(-100%);
          }
        }
      }

      > .menu-dropdown {
        border-top-color: ${props => theme.colors.primary};
        @media ${device.lg} {
          top: 10px;
          left: 0%;
          opacity: 0;
          transform: translateX(-110%);
          transition: 0.4s;
          pointer-events: none;
        }
        > .drop-menu-item {
          @media ${device.lg} {
            padding-left: 30px;
            padding-right: 30px;
          }
        }
      }
    }
  }

  &.dropdown-right {
    left: auto;
    right: -90%;
  }
`

export const SiteHeader = styled.header`
  position: absolute !important;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 9999998;
  background: ${props => setColor(props, black, lightGray)};

  @media ${device.xxl} {
    .navbar-collapse .navbar-nav {
      display: none !important;
    }
  }

  @media ${device.xl} {
    position: fixed !important;
    transition: 0.25s;

    &.reveal-header {
      transform: translateY(0%);
      box-shadow: 0 6px 34px -11px rgba(0, 0, 0, 0.2);
      z-index: 9999;
      background: ${props => setColor(props, dark, light)};
    }
  }
`

export const ToggleButton = styled.button`
  padding: 0.75rem !important;
  border: 0 !important;
  @media ${device.xxl} {
    display: block !important;
  }
`
